<template>
  <v-container>
    <v-row no-gutters>
      <span class="text-h2">
        {{ labels.promotionRequests }} {{ envName }}
      </span>
    </v-row>
    <v-row no-gutters class="py-5">
      <v-btn
        color="accent"
        :disabled="!canApplyPromotions"
        @click.prevent.stop="promote"
        >
        {{ labels.apply }}
      </v-btn>
      <v-btn
        class="ml-4"
        color="error"
        :disabled="!canDeletePromotions"
        @click.prevent.stop="deletePromotions"
        >
        {{ labels.delete }}
      </v-btn>
      <v-spacer />
    </v-row>
    <v-row no-gutters>
      <v-data-table
        v-if="promotions"
        v-model="promotionsToApply"
        :headers="headers"
        :items="promotions"
        :loading="loading"
        show-select
        class="flex"
      >
        <template v-slot:item.game_name="{ value }">
          <v-chip
            color="primary"
            dark
            label
          >
            {{ value }}
          </v-chip>
        </template>
        <template v-slot:item.backend_version="{ item, value }">
          <v-badge
            bordered
            inline
            :color="item.promote_backend ? 'success' : 'error'"
            :icon="item.promote_backend ? 'mdi-check' : 'mdi-close'"
          >
            {{ value }}
          </v-badge>
        </template>
        <template v-slot:item.frontend_version="{ item, value }">
          <v-badge
            bordered
            inline
            :color="item.promote_frontend ? 'success' : 'error'"
            :icon="item.promote_frontend ? 'mdi-check' : 'mdi-close'"
          >
            {{ value }}
          </v-badge>
        </template>
        <template v-slot:item.receiver_game_instance="{ value, item }">
          <span v-if="value">
            {{ value.game_backend_full_version }}
            <span v-if="value.game_frontend_version && item.promote_frontend">
              -{{ value.game_frontend_version }}
            </span>
          </span>
        </template>
        <template v-slot:item.created="{ value }">
          {{ formatDate(value) }}
        </template>
      </v-data-table>
      <v-skeleton-loader loading v-else type="table" class="flex" />
    </v-row>
  </v-container>
</template>

<script>
/* eslint no-unused-vars: ["error", { "args": "none" }] */
import { isEmpty } from 'lodash';
import { mapActions, mapState } from 'vuex';
import { labels } from '@/assets/texts.json';
import { formatDate } from '@/helpers';

export default {
  name: 'promotions',
  beforeDestroy() {
    clearInterval(this.timer);
  },
  created() {
    // this.clear('promotions');
    this.getPromotions();
    this.setTimerMethod(this.getPromotions);
    this.setInterval();
  },
  props: {
    envName: {
      type: String,
      required: true,
      default: '',
    },
    environmentID: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    headers: [
      {
        text: 'Game',
        value: 'game_name',
        sortable: true,
      },
      {
        text: 'Backend',
        value: 'backend_version',
        sortable: true,
      },
      {
        text: 'Frontend',
        value: 'frontend_version',
        sortable: true,
      },
      {
        text: 'Overrides',
        value: 'receiver_game_instance',
        sortable: true,
      },
      {
        text: 'Requested by',
        value: 'requested_by.username',
        sortable: true,
      },
      {
        text: 'On date',
        value: 'created',
        sortable: true,
      },
    ],
    labels,
    promotionsToApply: [],
  }),
  computed: {
    ...mapState('fetch', [
      'environment',
      'permissions',
      'promotions',
    ]),
    ...mapState('shared', [
      'loading',
    ]),
    canApplyPromotions() {
      return (
        this.permissions.game_promotion_apply
        && isEmpty(this.promotionsToApply)
      );
    },
    canDeletePromotions() {
      return (
        this.permissions.game_promotion_remove
        && isEmpty(this.promotionsToApply)
      );
    },
  },
  methods: {
    ...mapActions({
      applyPromotions: 'fetch/applyPromotions',
      clear: 'fetch/clear',
      clearInterval: 'fetch/clearInterval',
      deletePromotions: 'fetch/deletePromotions',
      fetchPromotions: 'fetch/fetchPromotions',
      setTimerMethod: 'fetch/setTimerMethod',
      setInterval: 'fetch/setInterval',
    }),
    getPromotions() {
      this.fetchPromotions(this.environmentID);
    },
    promote() {
      const body = {
        selection: this.promotionsToApply.map((promo) => promo.id),
      };
      this.applyPromotions({
        environmentID: this.environmentID,
        body,
      }).then(() => { this.getPromotions(); });
    },
    removePromotions() {
      const body = {
        selection: this.promotionsToApply.map((promo) => promo.id),
      };
      this.deletePromotions({ environmentID: this.environmentID, body });
    },
    formatDate,
  },
  watch: {
    refresh: {
      handler: function refresh(val, _) {
        if (val) {
          this.setInterval();
        } else {
          this.clearInterval();
        }
      },
    },
  },
};
</script>

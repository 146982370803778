var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('span',{staticClass:"text-h2"},[_vm._v(" "+_vm._s(_vm.labels.promotionRequests)+" "+_vm._s(_vm.envName)+" ")])]),_c('v-row',{staticClass:"py-5",attrs:{"no-gutters":""}},[_c('v-btn',{attrs:{"color":"accent","disabled":!_vm.canApplyPromotions},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.promote.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.labels.apply)+" ")]),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"error","disabled":!_vm.canDeletePromotions},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deletePromotions.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.labels.delete)+" ")]),_c('v-spacer')],1),_c('v-row',{attrs:{"no-gutters":""}},[(_vm.promotions)?_c('v-data-table',{staticClass:"flex",attrs:{"headers":_vm.headers,"items":_vm.promotions,"loading":_vm.loading,"show-select":""},scopedSlots:_vm._u([{key:"item.game_name",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"color":"primary","dark":"","label":""}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.backend_version",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-badge',{attrs:{"bordered":"","inline":"","color":item.promote_backend ? 'success' : 'error',"icon":item.promote_backend ? 'mdi-check' : 'mdi-close'}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.frontend_version",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-badge',{attrs:{"bordered":"","inline":"","color":item.promote_frontend ? 'success' : 'error',"icon":item.promote_frontend ? 'mdi-check' : 'mdi-close'}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.receiver_game_instance",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(value)?_c('span',[_vm._v(" "+_vm._s(value.game_backend_full_version)+" "),(value.game_frontend_version && item.promote_frontend)?_c('span',[_vm._v(" -"+_vm._s(value.game_frontend_version)+" ")]):_vm._e()]):_vm._e()]}},{key:"item.created",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDate(value))+" ")]}}],null,false,4242304860),model:{value:(_vm.promotionsToApply),callback:function ($$v) {_vm.promotionsToApply=$$v},expression:"promotionsToApply"}}):_c('v-skeleton-loader',{staticClass:"flex",attrs:{"loading":"","type":"table"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }